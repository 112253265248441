import {
  child,
  ref,
  getDatabase,
  orderByChild,
  equalTo,
  query,
  onChildChanged,
  onValue
} from 'firebase/database'

import { getFirebaseApp } from '@utils/Firebase'

import {
  IMessageData,
  IUserChatData
} from '@components/ui/organisms/Navbar/Navbar.types'

export const registerPromotionListener = (
  onChangeHandler: (message: IMessageData) => void
) => {
  const promotionsRef = query(
    child(ref(getDatabase(getFirebaseApp())), 'MessageNotifications'),
    orderByChild('type'),
    equalTo('promotion')
  )

  const unsubscribeAddListener = onChildChanged(
    promotionsRef,
    messageNotificationNodeSnap => {
      const messageNotificationObj = messageNotificationNodeSnap.val()
      const key = messageNotificationNodeSnap.ref.key

      onChangeHandler({
        ...messageNotificationObj,
        pushKey: key
      })
    }
  )

  const unsubscribeChangeListener = onChildChanged(
    promotionsRef,
    messageNotificationNodeSnap => {
      const messageNotificationObj = messageNotificationNodeSnap.val()
      const key = messageNotificationNodeSnap.ref.key

      onChangeHandler({
        ...messageNotificationObj,
        pushKey: key
      })
    }
  )

  return {
    unsubscribeAddListener,
    unsubscribeChangeListener
  }
}

export const registerMessageNotificationListener = (
  conversationId: string,
  onChangeHandler: (messageData: IUserChatData) => void
) => {
  const messageNotificationRef = query(
    child(
      ref(getDatabase(getFirebaseApp())),
      `MessageNotifications/${conversationId}`
    )
  )

  const unsubscribeChangeListener = onValue(
    messageNotificationRef,
    messageNotificationNodeSnap => {
      const messageNotificationObj = messageNotificationNodeSnap.val()
      const key = messageNotificationNodeSnap.ref.key

      onChangeHandler({
        ...messageNotificationObj,
        pushKey: key
      })
    }
  )

  return {
    unsubscribeChangeListener
  }
}
