import { Button } from '@components/ui/atoms'

import { styled, Badge, css } from '@mui/material'

export const StyledNavbar = styled('div')`
  position: sticky;
  top: 0;
  z-index: 9;
  background: rgba(0, 0, 22, 0.88);
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
`

export const StyledHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 9;

  @media (min-width: 992px) {
    display: block;
  }
`

export const StyledNavbarButton = styled('button', {
  shouldForwardProp: propName => propName !== 'isActive'
})<{ isActive?: boolean }>`
  gap: 4px;
  background: none;
  border: none;
  padding: 8px;
  color: var(--Neutral1);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ isActive = false }) => {
    if (isActive) {
      return css`
        background-color: var(--Secondary);
        border-radius: 4px;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: var(--Neutral1);

            svg {
              path {
                fill: var(--Neutral1);
              }
            }
          }
        }

        @media (min-width: 600px) {
          border-radius: 8px;
        }
      `
    }

    return css`
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: var(--Secondary);

          svg {
            path {
              fill: var(--Secondary);
            }
          }
        }
      }
    `
  }}
`

export const StyledMainMenu = styled('nav')`
  display: flex;
  gap: 32px;
  font-family: 'Poppins', sans-serif;
  text-align: right;

  @media screen and (max-width: 992px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    text-align: left;
  }

  ul {
    font-size: 16px;
    line-height: 22px;
    border-radius: 8px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: var(--Neutral1);
    list-style: none;
    position: relative;

    @media screen and (min-width: 768px) and (max-width: 991px) {
      display: block;
    }

    @media screen and (max-width: 992px) {
      display: block;
    }

    ul {
      position: absolute;
      left: 0;
      visibility: hidden;
      opacity: 0;
      bottom: 0;
      background: var(--Neutral8);
      text-align: left;
      width: 210px;
      transition: 0.3s;
      transform: translateY(100%) scaleY(0);
      transform-origin: top;

      li {
        display: block;
        cursor: pointer;
      }

      @media screen and (max-width: 992px) {
        bottom: 0;
        transform: scaleY(0);
      }
    }

    li {
      font-size: 16px;
      line-height: 22px;
      border-radius: 8px;
      display: inline-block;
      position: relative;
      cursor: pointer;

      @media screen and (max-width: 992px) {
        display: flex;
        flex-direction: row;
      }

      ul {
        margin-top: 10px;

        @media screen and (min-width: 768px) and (max-width: 991px) {
          margin-top: 0;
        }

        @media screen and (max-width: 992px) {
          position: relative;
          visibility: visible;
          background-color: transparent;
          opacity: 1;
          transform: none;
          width: 100%;
          box-shadow: none;
          margin-top: 0;
        }
      }

      li {
        a {
          @media screen and (min-width: 768px) and (max-width: 991px) {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 14px;
          }

          @media screen and (max-width: 992px) {
            font-family: 'Poppins', sans-serif;
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 14px;
          }
        }
      }

      a {
        display: inline-block;
        transition: 0.3s;

        @media screen and (min-width: 768px) and (max-width: 991px) {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 14px;
        }

        @media screen and (max-width: 992px) {
          font-family: 'Poppins', sans-serif;
          font-size: 21px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 14px;
        }
      }

      i {
        font-weight: bold;
        width: 16px;
        height: 16px;
        transform-origin: 10px;

        @media screen and (max-width: 992px) {
          transform-origin: 0px;
        }
      }

      &:last-child {
        ul {
          left: auto;
          right: 0;
          width: 150px;
        }

        @media screen and (max-width: 992px) {
          ul {
            width: 100%;
          }
        }
      }

      &:active,
      &:hover {
        ul {
          visibility: visible;
          opacity: 1;
          transform: translateY(100%) scaleY(1);

          @media screen and (max-width: 992px) {
            transform: scaleY(1);
          }
        }

        a {
          color: var(--Secondary);
        }

        > a {
          @media screen and (max-width: 992px) {
            color: var(--Neutral1);
            background: var(--SecondaryVariant);
          }
        }

        i {
          color: var(--Secondary) !important;
          transform: rotate(180deg);
          transition: transform 0.3s;
        }
      }
    }
  }

  li {
    li {
      a {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        display: block;
        position: relative;
        color: var(--Neutral1) !important;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 14px;

        &:after {
          position: absolute;
          background-size: 80px 80px;
          background-position: -18px -19px;
          top: 16px;
          left: 4px;
          height: 28px;
          width: 23px;
          background-repeat: no-repeat;
          transition: 0.3s;
          transition-duration: 0.3s;
          visibility: hidden;
          opacity: 0;
        }
      }

      &:hover,
      &:active,
      &:focus,
      &:focus-within,
      &:target {
        a {
          background: var(--SecondaryVariant);

          &:after {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      &:first-of-type {
        a {
          border-radius: 8px 8px 0 0;
        }
      }

      &:last-of-type {
        a {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }
`

export const StyledMainMenuList = styled('ul')`
  > li {
    padding: 8px 16px 32px 16px;
  }

  span {
    display: flex;
    flex-direction: row;
    gap: 32px;
    height: 100%;

    li {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    span {
      display: initial;
    }
  }

  @media screen and (max-width: 992px) {
    background: var(--Neutral10);
    display: flex;
    flex-direction: column;
    width: 300px;

    span {
      display: initial;
      height: initial;

      li {
        display: initial;
      }
    }

    a {
      width: 100%;
    }
  }
`

export const StyledLogo = styled('div')`
  a {
    padding-top: 10px;
    display: block;
  }
`

export const StyledMobileLogo = styled(StyledLogo)`
  display: block;

  @media (min-width: 992px) {
    display: none;
  }
`

export const StyledDesktopLogo = styled(StyledLogo)`
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`

export const StyledButton = styled(Button)`
  padding: 10px 24px; !important;
`

export const StyledMainMenuButtonSection = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  margin-top: auto;

  @media (min-width: 992px) {
    flex-direction: row;
    padding: 0 0 0 32px;
  }
`

export const StyledScrollContainer = styled('span')`
  @media screen and (max-width: 992px) {
    overflow: auto;
  }
`

export const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    border-radius: 100px;
    background-color: var(--Primary);
    color: var(--Neutral1) !important;
  }
`
